
<script setup>
    import { ref, computed, onMounted, onBeforeUnmount } from "vue";
    import { isMobileApp } from "mobile.utils.ts";
    import * as drawer from "mobile.controls.Drawer.ts";
    import { context, contextName } from "mobile.controls.Context.ts";

    import MPill from "mobile.vue.components.Pill.vue";

    const props = defineProps({
        filter: {
            type: Object,
            requried: true,
        },
    });

    const pills = computed(() => {
        const pills = [];

        for (let field of props.filter.fields) {
            for (let pill of field.getPills()) {
                pills.push(pill);
            }
        }

        return pills;

        // return filter.fields.reduce((pills, field) => pills.concat(field.getPills()), []);
    });

    function openContextSelector() {
        //drawer.showDrawer();
        drawer.openContextSelector();
    }

    onMounted(() => {

    });

    onBeforeUnmount(() => {

    });
</script>

<template>
    <template v-if="pills.length || isMobileApp">
        <div class="flex-shrink-0 d-flex gap-2 hide-scrollbar" style="overflow-x: auto;">
            <template v-if="isMobileApp">
                <MPill color="primary-subtle" style="font-size: 0.9em; font-weight: 400; max-width: 12rem;" @click="openContextSelector()">
                    {{ $t("Context") }}: {{ contextName }}
                </MPill>
            </template>
            <template v-for="pill in pills">
                <m-pill color="primary-subtle" style="font-size: 0.9em; font-weight: 400; max-width: 70vw" @click="pill.click" @close="pill.close">{{ pill.text }}</m-pill>
            </template>
        </div>
    </template>
</template>

<style scoped>
    .hide-scrollbar {
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { display: none; }
    }
</style>
